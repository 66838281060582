import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ElmsUtils } from 'core/utils';
import {
  IAccreditationAgency,
  IQueryAccreditationAgency,
} from 'modules/accreditation-agencies/models/accreditation-agency.model';
import { Observable } from 'rxjs';

export enum AccreditationAgencyEndpoints {
  GET = '/a/accreditation-agencies/:accreditationAgencyId/',
  QUERY = '/a/accreditation-agencies/',
  PUT = '/a/accreditation-agencies/:accreditationAgencyId/',
  POST = '/a/accreditation-agencies/',
  DELETE = '/a/accreditation-agencies/:accreditationAgencyId/',
}

@Injectable()
export class AccreditationAgencyService {
  constructor(protected http: HttpClient) {}

  get(agencyId: number): Observable<IAccreditationAgency> {
    return this.http.get<IAccreditationAgency>(
      ElmsUtils.formatUrl(AccreditationAgencyEndpoints.GET, { accreditationAgencyId: agencyId }),
    );
  }

  query(params, throwHttpErrors = false): Observable<IQueryAccreditationAgency> {
    let queryParams = new HttpParams();

    if (params.limit) {
      queryParams = queryParams.append('limit', params.limit).append('offset', params.offset);
    }

    return this.http.get<IQueryAccreditationAgency>(ElmsUtils.formatUrl(AccreditationAgencyEndpoints.QUERY, {}), {
      params: queryParams,
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }
}
