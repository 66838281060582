<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Clone</h4>
</div>

<div class="modal-body">
  <p *ngIf="!reloading">Create a copy of '{{ quiz.title }}'?</p>

  <div *ngIf="reloading && !cloneError">
    <div class="load-spinner"></div>
  </div>
  <div class="alert alert-sm alert-danger" *ngIf="cloneError">{{ cloneError }}</div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="closeModal()" [disabled]="cloning">Cancel</button>
  <button
    type="button"
    [disabled]="reloading || cloning"
    [ladda]="cloning"
    class="btn btn-primary"
    (click)="cloneQuiz()">
    Clone
  </button>
</div>
