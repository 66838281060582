<div ngModelGroup="questionForm" #questionForm="ngModelGroup">
  <div *ngIf="!question.typeId">
    <select-question-type
      [question]="question"
      [questionTypes]="questionTypes"
      *ngIf="!useFlatSelect"
      [quizTypeId]="question.targetQuizTypeId"
      [scoreTypeId]="scoreTypeId"></select-question-type>

    <select-question-type-flat
      [question]="question"
      [questionTypes]="questionTypes"
      *ngIf="useFlatSelect"
      [quizTypeId]="question.targetQuizTypeId"
      [scoreTypeId]="scoreTypeId"></select-question-type-flat>
  </div>

  <div *ngIf="question.typeId">
    <div>
      <div class="form-group no-margin-bottom">
        <div
          class=""
          [ngClass]="{
            'has-error': questionText.invalid && (formOptions?.submitted || questionText.dirty),
            'col-xs-12': !showColumns,
          }">
          <div class="" [ngClass]="showColumns ? ' ' : 'flex flex-vertical-center'">
            <div class="" [ngClass]="showColumns ? 'row' : 'flex-row'">
              <div class="col-sm-3 col-xs-12" *ngIf="showColumns">
                <label [for]="'questionText'" class="font-bold">Question text</label>
              </div>

              <div class="" [ngClass]="showColumns ? 'col-sm-9 col-xs-12' : 'flex-col flex-col-grow'">
                <markdown-editor-component
                  [(ngModel)]="question.text"
                  name="questionText"
                  [required]="true"
                  [rows]="5"
                  #questionText="ngModel"
                  [id]="'questionText'"
                  [disableAttachments]="true"
                  [maxlength]="maxlength || 2500"
                  noWhiteSpace
                  autoFocus></markdown-editor-component>
                <p
                  class="help-block no-margin-bottom"
                  *ngIf="questionText.invalid && (formOptions?.submitted || questionText.dirty)">
                  <span *ngIf="questionText.errors?.required">Question text is required.</span>
                  <span *ngIf="questionText.errors?.maxlength">Question text length is to big.</span>
                </p>

                <div *ngIf="question.image?.id" class="row sm-margin-bottom sm-margin-top">
                  <div class="col-sm-4 thumbnail-control col-xs-12">
                    <img
                      style="max-height: 100%; max-width: 100%"
                      class="thumbnail sm-margin-bottom"
                      alt="Question image"
                      src="/a/media/file/{{ question.image.id }}/?rnd={{ question.image.rnd }}" />
                  </div>

                  <div class="col-sm-4 col-xs-12 thumbnail-control">
                    <button type="button" class="btn btn-danger btn-block-xs" (click)="question.image = null">
                      Remove image
                    </button>
                  </div>
                </div>

                <div *ngIf="!question.image?.id" class="sm-margin-bottom sm-margin-top">
                  <image-upload [(tmpFile)]="questionImage" [options]="fileUploadOptions" [simpleView]="true">
                  </image-upload>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="assign-quiz-question" *ngIf="categories.length">
        <assign-quiz-question-category [question]="question" class="row" [formOptions]="formOptions">
        </assign-quiz-question-category>
      </div>

      <!--randomAnswerOrder-->
      <div class="form-group xs-margin-bottom" *ngIf="question.skipQuestionAvailable">
        <div class="col-sm-12 col-xs-12">
          <div class="checkbox">
            <label>
              <input
                id="optionalQuestion"
                name="optionalQuestion"
                type="checkbox"
                [checked]="!question.optional"
                (change)="question.optional = !question.optional" />
              Required
            </label>
          </div>
        </div>
      </div>

      <!--randomAnswerOrder-->
      <div
        class="form-group xs-margin-bottom"
        *ngIf="
          !hideRandomOrder &&
          question.typeExtensionId !== questionTypeExtension.likertScale.id &&
          QuizQuestionType.shortAnswer !== question.typeId
        ">
        <div class="col-sm-9 col-xs-12">
          <div class="checkbox">
            <label>
              <input
                id="randomAnswerOrder"
                name="randomAnswerOrder"
                type="checkbox"
                [(ngModel)]="question.randomAnswerOrder" />
              Show answer options in a random order
            </label>
          </div>
        </div>
      </div>

      <!--Active-->
      <div class="form-group xs-margin-bottom" *ngIf="!hideActivity">
        <div class="col-sm-9 col-xs-12">
          <div class="checkbox">
            <label>
              <input id="questionActive" name="questionActive" type="checkbox" [(ngModel)]="question.active" /> Active
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
