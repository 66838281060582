<div class="modal-header">
  <button [disabled]="deletionProgress" type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Instructor</h4>
</div>

<div class="modal-body">
  <p>Remove Instructor?</p>
  <div class="alert alert-sm alert-danger" *ngIf="deleteError.length">{{ deleteError }}</div>
</div>

<div class="modal-footer">
  <fieldset [disabled]="deletionProgress" class="no-margin-bottom">
    <button type="button" class="btn btn-warning" (click)="cancel()" autoFocus>Cancel</button>
    <button type="button" [ladda]="deletionProgress" class="btn btn-success" (click)="submit()">OK</button>
  </fieldset>
</div>
