import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IQuestion } from 'modules/quiz/models/quiz.model';
import { QuizQuestionService } from 'modules/quiz/services/quiz-question.service';
import { Unsubscribable, catchError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'delete-quiz-question-modal',
  templateUrl: './delete-quiz-question.component.html',
})
export class DeleteQuizQuestionComponent implements OnInit, OnDestroy {
  question: IQuestion;
  quizTypeName: string;
  hasParent = false;
  previewError: string;

  deleteError = '';
  deletionProgress = false;
  reloading = false;
  private subscriber?: Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    private quizQuestionService: QuizQuestionService,
  ) {}

  ngOnInit() {
    this.question.hasParent = !!this.question.parentId;
  }

  deleteQuestion() {
    this.deletionProgress = true;
    this.subscriber = this.quizQuestionService
      .delete(this.question.id, true)
      .pipe(
        tap(() => {
          this.activeModal.close(true);
        }),
        catchError((err: HttpErrorResponse) => {
          this.deleteError = err.error;

          return null;
        }),
        finalize(() => {
          this.subscriber.unsubscribe();
          delete this.subscriber;
          this.deletionProgress = false;
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
