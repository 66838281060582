<div [ngModelGroup]="'questionAnswersForm' + question.globalId">
  <!--randomAnswerOrder-->
  <div
    class="form-group row margin-bottom"
    *ngIf="
      showRandomOrder &&
      question.typeExtensionId !== questionTypeExtension.likertScale.id &&
      questionAnswerTypes.shortAnswer !== question.typeId
    ">
    <div class="col-sm-9 col-xs-12">
      <div class="checkbox">
        <label>
          <input
            id="randomAnswerOrder"
            name="randomAnswerOrder"
            type="checkbox"
            [(ngModel)]="question.randomAnswerOrder" />
          Show answer options in a random order
        </label>
      </div>
    </div>
  </div>

  <!--validation messages block START-->
  <div class="required" [ngClass]="{ 'has-error panel panel-body': questionChoices.invalid && formOptions.submitted }">
    <input
      type="text"
      class="hidden"
      name="questionChoices"
      [ngModel]="questionValidationObject"
      valid-question-restrictions
      #questionChoices="ngModel"
      [exclude]="!scorrable ? ['correctLack'] : []" />
    <div class="error-block no-margin-bottom" *ngIf="questionChoices.invalid && formOptions.submitted">
      <span
        *ngIf="questionChoices.errors?.minVariants"
        [ngSwitch]="questionRestrictions[question.typeId * 100 + (question.typeExtensionId || 0)]?.minVariants">
        At least {{ questionRestrictions[question.typeId * 100 + (question.typeExtensionId || 0)]?.minVariants }}
        <ng-template ngSwitchCase="1">answer is</ng-template>
        <ng-template ngSwitchDefault>answers are</ng-template>
        required.</span
      >
      <span *ngIf="questionChoices.errors?.maxVariants">This question is not supposed to have answers.</span>
      <span
        *ngIf="questionChoices.errors?.correctLimit"
        [ngSwitch]="questionRestrictions[question.typeId * 100 + (question.typeExtensionId || 0)].correctLimit">
        It's not allowed to mark more than
        {{ questionRestrictions[question.typeId * 100 + (question.typeExtensionId || 0)].correctLimit }}
        <ng-template ngSwitchCase="1">answer</ng-template>
        <ng-template ngSwitchDefault>answers</ng-template> as "Correct".</span
      >
      <span *ngIf="questionChoices.errors?.correctLack">At least one correct answer is required.</span>
      <span *ngIf="questionChoices.errors?.hasTextDuplicates">The question contains duplicates of answer text.</span>
      <span *ngIf="questionChoices.errors?.hasTextAnswerDuplicates"
        >The question contains duplicates of answer result text.</span
      >
    </div>
  </div>
  <!--validation messages block END-->

  <!--Draggable Question Answer Variant list START-->
  <ul
    class="list-group list-group-variants sm-margin-bottom"
    cdkDropList
    (cdkDropListDropped)="dropCallback($event)"
    [ngClass]="{ 'no-margin-bottom': !question.answerVariants.length }">
    <!--Header for Likert questions only-->
    <li class="list-group-header" *ngIf="question.typeExtensionId === questionTypeExtension.likertScale.id">
      <div class="flex font-bold">
        <div class="flex-row">
          <div class="flex-col flex-col-3x margin-right-2x">Rating</div>
          <div class="flex-col flex-col-grow">Description</div>
        </div>
      </div>
    </li>

    <!--Question Answer Variant info START-->
    <li
      class="list-group-item"
      *ngFor="let answerVariant of question.answerVariants; let $index = index"
      [cdkDragData]="answerVariant"
      cdkDrag
      [cdkDragLockAxis]="'y'"
      [ngClass]="{ isEdit: isEditMode() }">
      <div class="quiz answer">
        <div class="flex flex-vertical-top">
          <!-- top image row, visible xs only-->
          <div class="flex-row visible-xs" *ngIf="answerVariant.image?.id || answerVariant.tmpImageFile">
            <div class="flex-col">
              <div>
                <div *ngIf="answerVariant.image?.id" class="thumbnail-control" style="height: auto">
                  <img
                    style="max-height: 100%; max-width: 100%"
                    alt="Answer variant image"
                    class="thumbnail standard-margin-bottom"
                    src="/a/media/file/{{ answerVariant.image.id }}/?rnd={{ answerVariant.image.rnd }}" />
                </div>
                <thumbnail
                  class="thumbnail-image"
                  *ngIf="answerVariant.tmpImageFile"
                  [file]="tmpFiles[answerVariant.tmpImageFile.tmpName].file"
                  [params]="{ width: 80, height: 80 }"></thumbnail>
              </div>
            </div>
          </div>
          <!--end image row-->
          <!--general row-->
          <div class="flex-row flex-vertical-top gap-5">
            <div class="flex-col flex-col-1x hide-edit hidden-xs" cdkDragHandle>
              <span class="use-move-cursor">
                <i class="fa-solid fa-grip-vertical"></i> <span class="sr-only">Move item</span>
              </span>
            </div>
            <!--show check only for those types:  -->
            <div
              class="flex-col"
              *ngIf="
                (question.typeId === questionAnswerTypes.multiChoice ||
                  question.typeId === questionAnswerTypes.checkAllThatApply ||
                  question.typeId === questionAnswerTypes.shortAnswer) &&
                question.typeExtensionId !== questionTypeExtension.likertScale.id
              ">
              <span *ngIf="answerVariant.correct && scorrable" [ngClass]="{ 'hidden-xs': !answerVariant.correct }">
                <span class="correct-answer-mark">
                  <i class="fa fa-check text-success fa-2x inline-block font-18px" aria-hidden="true"></i>
                </span>
              </span>
            </div>

            <!--likert index-->
            <div
              class="flex-col flex-col-2x text-center margin-right-2x"
              *ngIf="question.typeExtensionId === questionTypeExtension.likertScale.id">
              <div class="badge">
                {{ question.answerVariants.length - $index }}
              </div>
            </div>

            <!--image, hidden xs and moved to top row !-->
            <div
              class="flex-col standard-margin-right hidden-xs sm-margin-left"
              *ngIf="answerVariant.image?.id || answerVariant.tmpImageFile">
              <div>
                <div *ngIf="answerVariant.image?.id" class="thumbnail-control" style="height: auto">
                  <img
                    style="max-height: 100%; max-width: 100%"
                    alt="Answer variant image"
                    class="thumbnail standard-margin-bottom"
                    src="/a/media/file/{{ answerVariant.image.id }}/?rnd={{ answerVariant.image.rnd }}" />
                </div>
                <thumbnail
                  class="thumbnail-image"
                  *ngIf="answerVariant.tmpImageFile"
                  [file]="tmpFiles[answerVariant.tmpImageFile.tmpName].file"
                  [params]="{ width: 80, height: 80 }"></thumbnail>
              </div>
            </div>

            <!--Answer Fields START-->
            <div class="flex-col flex-col-grow">
              <div
                class=""
                *ngIf="
                  question.typeId === questionAnswerTypes.multiChoice ||
                  question.typeId === questionAnswerTypes.checkAllThatApply ||
                  question.typeId === questionAnswerTypes.shortAnswer
                ">
                <div class="display-inline-block" [innerHtml]="answerVariant.text | markdownToHtml"></div>
              </div>

              <div *ngIf="question.typeId === questionAnswerTypes.matching" class="xs-margin-left">
                <div class="display-inline-block" [innerHtml]="answerVariant.text | markdownToHtml"></div>
              </div>

              <div *ngIf="question.typeId === questionAnswerTypes.fillin" class="xs-margin-left">
                <div>
                  <em>Answer field {{ $index + 1 }}:</em>&nbsp;
                  <div class="display-inline-block" [innerHtml]="answerVariant.variants | markdownToHtml"></div>
                </div>
              </div>
            </div>
            <!--Answer Fields END-->

            <!--show check only for those types:  -->
            <div
              class="flex-col flex-col-2x correct-answer-mark xs-margin-right text-center"
              *ngIf="
                (question.typeId === questionAnswerTypes.multiChoice ||
                  question.typeId === questionAnswerTypes.checkAllThatApply) &&
                question.typeExtensionId !== questionTypeExtension.likertScale.id &&
                showOther
              ">
              <span *ngIf="answerVariant.otherRequired" [ngClass]="{ 'hidden-xs': !answerVariant.otherRequired }">
                <span class="label label-success">other</span>
              </span>
            </div>

            <!--dropdown right-side actions -->
            <div class="flex-col text-right hide-edit">
              <div ngbDropdown>
                <div>
                  <a href="#" title="Manage answer" class="dot-menu-large" ngbDropdownToggle>
                    <span class="fa fa-ellipsis-v" aria-hidden="true"></span>
                    <span class="sr-only">Manage answer</span>
                  </a>
                </div>

                <div ngbDropdownMenu class="dropdown-menu-right">
                  <a title="Edit" href="#" ngbDropdownItem (click)="editAnswerVariant(answerVariant)">Edit</a>
                  <a
                    title="Delete"
                    href="#"
                    ngbDropdownItem
                    (clickConfirmation)="deleteAnswerVariant(answerVariant)"
                    *ngIf="!question.hasAnswers"
                    clickConfirmationMessage="Delete answer?">
                    <span class="text-danger">Delete</span>
                  </a>
                  <span
                    class="disabled use-ban-cursor"
                    *ngIf="question.hasAnswers"
                    ngbDropdownItem
                    [disabled]="true"
                    tabindex="-1"
                    aria-disabled="true">
                    Delete
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end quiz answer wrapper-->

      <!--Edit Answer Form START-->
      <edit-question-choice
        *ngIf="
          answerVariantToEdit === answerVariant &&
          (question.typeId === questionAnswerTypes.multiChoice ||
            question.typeId === questionAnswerTypes.checkAllThatApply ||
            question.typeId === questionAnswerTypes.shortAnswer)
        "
        [answer]="answerVariant"
        [question]="question"
        [otherEditable]="showOther"
        class="edit-choice"
        [scorable]="scorrable"
        (saveHandler)="saveAnswerVariant($event)"
        (cancelHandler)="cancelEdit()"></edit-question-choice>

      <edit-question-matching
        *ngIf="answerVariantToEdit === answerVariant && question.typeId === questionAnswerTypes.matching"
        [answer]="answerVariant"
        [question]="question"
        class="edit-choice"
        (saveHandler)="saveAnswerVariant($event)"
        (cancelHandler)="cancelEdit()"></edit-question-matching>

      <edit-question-fillin
        *ngIf="answerVariantToEdit === answerVariant && question.typeId === questionAnswerTypes.fillin"
        [answer]="answerVariant"
        [question]="question"
        class="edit-choice"
        (saveHandler)="saveAnswerVariant($event)"
        (cancelHandler)="cancelEdit()"></edit-question-fillin>
      <!--Edit Answer Form END-->
    </li>
    <!--Question Answer Variant info END-->
  </ul>

  <!--multichoice, short, check all-->
  <div
    class="multichoice-q add-question-answer-panel"
    *ngIf="
      (question.typeId === questionAnswerTypes.multiChoice ||
        question.typeId === questionAnswerTypes.checkAllThatApply ||
        question.typeId === questionAnswerTypes.shortAnswer) &&
      (questionRestrictions[question.typeId * 100 + (question.typeExtensionId || 0)]?.maxVariants === null ||
        questionRestrictions[question.typeId * 100 + (question.typeExtensionId || 0)]?.maxVariants <
          question.answerVariants.length) &&
      (!isEditMode() || isObjectEmpty(answerVariantToEdit))
    ">
    <edit-question-choice
      *ngIf="isObjectEmpty(answerVariantToEdit)"
      [question]="question"
      [scorable]="scorrable"
      [otherEditable]="showOther"
      (saveHandler)="saveAnswerVariant($event)"
      (cancelHandler)="cancelEdit()">
    </edit-question-choice>

    <div
      *ngIf="
        !isEditMode() &&
        (questionRestrictions[question.typeId * 100 + (question.typeExtensionId || 0)]?.maxVariants === null ||
          questionRestrictions[question.typeId * 100 + (question.typeExtensionId || 0)]?.maxVariants <
            question.answerVariants.length)
      ">
      <a href="#" class="btn btn-default sm-margin-bottom" (click)="editAnswerVariant()"
        ><span class="fa fa-plus inline-block xs-margin-right"></span>Add an answer
      </a>

      <a
        href="#"
        class="btn btn-default sm-margin-bottom sm-margin-left"
        *ngIf="scorrable && messageEditMode === 'inside'"
        (click)="setAnswerFeedback()"
        ><span class="fa fa-comments inline-block xs-margin-right"></span> Set answer feedback messages
      </a>
    </div>
  </div>

  <!--fillin-->
  <div
    class="fillin-q add-question-answer-panel"
    *ngIf="question.typeId === questionAnswerTypes.fillin && (!isEditMode() || isObjectEmpty(answerVariantToEdit))">
    <edit-question-fillin
      *ngIf="isObjectEmpty(answerVariantToEdit)"
      [question]="question"
      (saveHandler)="saveAnswerVariant($event)"
      (cancelHandler)="cancelEdit()">
    </edit-question-fillin>

    <div *ngIf="!isEditMode()">
      <a href="#" class="btn btn-default sm-margin-bottom" (click)="editAnswerVariant()"
        ><span class="fa fa-plus inline-block standard-margin-right"></span>Add an answer field
      </a>

      <a
        href="#"
        class="btn btn-default sm-margin-bottom sm-margin-left"
        *ngIf="scorrable && messageEditMode === 'inside'"
        (click)="setAnswerFeedback()"
        ><span class="fa fa-comments inline-block xs-margin-right"></span> Set answer feedback messages
      </a>
    </div>
  </div>

  <!--matching-->
  <div
    class="matching-q add-question-answer-panel"
    *ngIf="question.typeId === questionAnswerTypes.matching && (!isEditMode() || isObjectEmpty(answerVariantToEdit))">
    <edit-question-matching
      *ngIf="isObjectEmpty(answerVariantToEdit)"
      [question]="question"
      (saveHandler)="saveAnswerVariant($event)"
      (cancelHandler)="cancelEdit()">
    </edit-question-matching>

    <div *ngIf="!isEditMode()">
      <a href="#" class="btn btn-default sm-margin-bottom" (click)="editAnswerVariant()"
        ><span class="fa fa-plus inline-block xs-margin-right"></span>Add an answer
      </a>

      <a
        href="#"
        class="btn btn-default sm-margin-bottom sm-margin-left"
        *ngIf="scorrable && messageEditMode === 'inside'"
        (click)="setAnswerFeedback()"
        ><span class="fa fa-comments inline-block xs-margin-right"></span> Set answer feedback messages
      </a>
    </div>
  </div>

  <!--Feedback answers, inline view-->
  <div *ngIf="scorrable && messageEditMode === 'inline'">
    <fieldset name="correctFeedback">
      <legend><label [for]="'correctFeedback'" class="font-weight-normal">Correct feedback</label></legend>
      <!--Correct Answer Feedback-->
      <div class="form-group row">
        <div class="col-xs-12">
          <markdown-editor-component
            [(ngModel)]="question.correctFeedback"
            name="correctFeedback"
            [rows]="5"
            #correctFeedback="ngModel"
            class="form-control"
            [id]="'correctFeedback'"></markdown-editor-component>
        </div>
      </div>
    </fieldset>

    <fieldset class="sm-margin-top" name="incorrectFeedback">
      <legend><label [for]="'incorrectFeedback'" class="font-weight-normal">Incorrect feedback</label></legend>
      <div class="form-group row">
        <div class="col-xs-12">
          <markdown-editor-component
            [(ngModel)]="question.incorrectFeedback"
            name="incorrectFeedback"
            [rows]="5"
            #incorrectFeedback="ngModel"
            class="form-control"
            [id]="'correctFeedback'"></markdown-editor-component>
        </div>
      </div>
    </fieldset>
  </div>

  <!--Feedback answers, modal view-->
  <div class="row" *ngIf="scorrable && messageEditMode === 'outside'">
    <div class="col-xs-12">
      <a href="#" class="btn btn-default sm-margin-bottom" (click)="setAnswerFeedback()"
        ><span class="fa fa-comments inline-block xs-margin-right"></span> Set answer feedback messages
      </a>
    </div>
  </div>
</div>
