import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CACHE_NAME_TOKEN,
  CACHE_TTL_TOKEN,
  FLUSH_CACHE_TOKEN,
  TTL,
} from 'core/http-interceptors/http-caching-interceptor';
import { ElmsUtils } from 'core/utils';
import { IQuizQuestionCategory } from 'modules/admin/quiz/question/category/models/category.model';
import { Observable } from 'rxjs';

export interface IQuizCategoryResponse {
  items: IQuizQuestionCategory[];
}

enum QuizCategoryEndpoints {
  QUERY = '/a/quizzes/categories/',
  GET = '/a/quizzes/categories/:categoryId/',
  PUT = '/a/quizzes/categories/:categoryId/',
  POST = '/a/quizzes/categories/',
}

@Injectable()
export class QuizQuestionsCategoryService {
  constructor(private http: HttpClient) {}

  public get(categoryId: number): Observable<IQuizQuestionCategory> {
    return this.http.get<IQuizQuestionCategory>(ElmsUtils.formatUrl(QuizCategoryEndpoints.GET, { categoryId }), {
      context: new HttpContext().set(CACHE_NAME_TOKEN, 'QuizCategoryCache').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }

  public post(payload: Partial<IQuizQuestionCategory>): Observable<IQuizQuestionCategory> {
    return this.http.post<IQuizQuestionCategory>(QuizCategoryEndpoints.POST, payload, {
      context: new HttpContext().set(FLUSH_CACHE_TOKEN, 'QuizCategoryCache'),
    });
  }

  public update(categoryId: number, payload: Partial<IQuizQuestionCategory>): Observable<IQuizQuestionCategory> {
    return this.http.put<IQuizQuestionCategory>(
      ElmsUtils.formatUrl(QuizCategoryEndpoints.PUT, { categoryId }),
      payload,
      {
        context: new HttpContext().set(FLUSH_CACHE_TOKEN, 'QuizCategoryCache'),
      },
    );
  }

  public delete(categoryId: number | string): Observable<IQuizQuestionCategory> {
    return this.http.delete<null>(ElmsUtils.formatUrl(QuizCategoryEndpoints.GET, { categoryId }), {
      context: new HttpContext().set(FLUSH_CACHE_TOKEN, 'QuizCategoryCache'),
    });
  }

  public query(params: { active?: boolean; parent_id?: string | number }): Observable<IQuizCategoryResponse> {
    return this.http.get<IQuizCategoryResponse>(QuizCategoryEndpoints.QUERY, {
      context: new HttpContext().set(CACHE_NAME_TOKEN, 'QuizCategoryCache').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
      params: new HttpParams({ fromObject: params }),
    });
  }
}
