<div class="panel panel-inline-edit" dnd-nodrag>
  <div class="panel-heading clearfix">
    <fieldset [disabled]="fileUploadProgress" class="no-margin-bottom">
      <span>Edit question answer</span>
      <button
        [disabled]="!editQuestionChoiceForm.valid"
        (click)="submit(editQuestionChoiceForm)"
        type="button"
        title="Save an answer"
        class="pull-right btn btn-success btn-sm">
        <span class="fa fa-check"></span>
      </button>
      <button
        (click)="cancel()"
        type="button"
        title="Cancel question answer edit"
        class="pull-right btn btn-danger btn-sm">
        <span class="fa fa-times"></span>
      </button>
    </fieldset>
  </div>
  <form class="panel-body" name="editQuestionChoiceForm" #editQuestionChoiceForm="ngForm">
    <!--Choice Text-->
    <div class="form-group">
      <div class="col-xs-12" [ngClass]="{ 'has-error': choiceText.invalid && choiceText.dirty }">
        <label class="sr-only" [for]="'choiceText'">Question choice text</label>
        <markdown-editor-component
          [(ngModel)]="answerDraft.text"
          name="choiceText"
          [required]="true"
          [rows]="5"
          #choiceText="ngModel"
          [id]="'choiceText'"
          [disableAttachments]="true"
          [maxlength]="1000"
          noWhiteSpace
          autoFocus></markdown-editor-component>
        <p class="help-block" *ngIf="choiceText.invalid && choiceText.dirty">
          <span *ngIf="choiceText.errors?.required">Answer text is required.</span>
        </p>
      </div>
    </div>

    <!--Choice Image-->
    <div class="form-group">
      <div class="col-sm-12">
        <div *ngIf="answerDraft.image?.id" class="row">
          <div class="col-sm-4 thumbnail-control" style="height: 100px">
            <img
              style="max-height: 100%; max-width: 100%"
              class="thumbnail standard-margin-bottom"
              alt="Answer image"
              src="/a/media/file/{{ answerDraft.image.id }}/?rnd={{ answerDraft.image.rnd }}" />
          </div>

          <div class="col-sm-4 thumbnail-control">
            <button type="button" class="btn btn-danger" (click)="answerDraft.image = null" title="Remove image">
              <span class="fa-solid fa-trash-can"></span> Remove
            </button>
          </div>
        </div>
        <div *ngIf="!answerDraft.image?.id">
          <image-upload [(tmpFile)]="answerImage" [options]="fileUploadOptions" [simpleView]="true"> </image-upload>
        </div>
      </div>

      <!--Choice Correct flag-->
      <div class="col-sm-12" *ngIf="scorable && question.typeExtensionId !== questionTypeExtension.likertScale.id">
        <div class="checkbox">
          <label for="correctChoice">
            <input
              id="correctChoice"
              name="correctChoice"
              type="checkbox"
              [disabled]="!correctnessEditable"
              #correctChoice="ngModel"
              [(ngModel)]="answerDraft.correct" />
            Correct</label
          >
        </div>
      </div>

      <!--Choice Other required flag-->
      <div class="col-sm-12" *ngIf="otherEditable">
        <div class="checkbox">
          <label for="otherRequired">
            <input
              id="otherRequired"
              name="correctChoice"
              type="checkbox"
              #correctChoice="ngModel"
              [(ngModel)]="answerDraft.otherRequired" />
            Other</label
          >
        </div>
      </div>
    </div>
  </form>
</div>
