import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AccreditationAgencyService } from 'modules/accreditation-agencies/services/accreditation-agency.service';

@NgModule({
  imports: [HttpClientModule],
  declarations: [],
  providers: [AccreditationAgencyService],
  exports: [],
})
export class AccreditationAgenciesModule {}
