import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IQuestion } from 'modules/quiz/models/quiz.model';
import { QuizQuestionService } from 'modules/quiz/services/quiz-question.service';
import { Unsubscribable, catchError } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'preview-quiz-question-modal',
  templateUrl: './question-preview.component.html',
})
export class PreviewQuizQuestionComponent implements OnInit, OnDestroy {
  question?: IQuestion;
  scoreTypeId?: number;
  reload = false;

  previewError: string;
  reloading = false;
  private subscriber?: Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    private quizQuestionService: QuizQuestionService,
  ) {}

  ngOnInit() {
    if (this.reload) {
      this.reloading = true;
      this.subscriber = this.quizQuestionService
        .get(this.question.id, null, true)
        .pipe(
          finalize(() => {
            this.subscriber?.unsubscribe();
            delete this.subscriber;
            this.reloading = false;
          }),
          catchError(() => {
            this.previewError = 'Failed to load quiz question';

            return null;
          }),
        )
        .subscribe((result) => {
          if (result) {
            this.question = result as IQuestion;
          }
        });
    }
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
