<form ngForm="quizQuestionsForm" #quizQuestionsForm="ngForm" name="quizQuestionsForm">
  <div class="row standard-margin-bottom">
    <div class="col-sm-12">
      <button
        [disabled]="isEditMode()"
        class="btn btn-default"
        type="button"
        title="Add a new question"
        (click)="editQuestion({})"
        *ngIf="canAddQuestion">
        <span class="fa fa-plus"></span> Add new question
      </button>
      <button
        [disabled]="isEditMode()"
        type="button"
        title="Add a new question"
        class="btn btn-default standard-margin-right sm-margin-bottom-phone"
        (click)="findQuestion()"
        *ngIf="questionBankEnabled">
        <span class="fa fa-search"></span> Add questions
      </button>
    </div>
  </div>

  <!-- Add question module -->
  <form
    class="panel panel-inline-edit"
    ngForm="addQuestionForm"
    #addQuestionForm="ngForm"
    name="addQuestionForm"
    *ngIf="questionToEdit && !questionToEdit.question?.id && !questionToEdit.globalId">
    <div class="panel-heading clearfix">
      <fieldset>
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <h4 class="xs-margin-top panel-title">Add new question</h4>
          </div>
          <div class="col-sm-6 col-xs-12 sm-margin-top-phone">
            <button
              [disabled]="!addQuestionForm.valid"
              (click)="saveQuestion()"
              type="button"
              title="Save Question"
              *ngIf="questionDraft.question.typeId"
              class="pull-right pull-left-xs btn btn-success btn-sm">
              <span class="fa fa-check"></span>
            </button>
            <button
              (click)="cancelEdit()"
              type="button"
              title="Cancel Question Edit"
              class="pull-right pull-left-xs btn btn-danger btn-sm">
              <span class="fa fa-times"></span>
            </button>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="panel-body">
      <h4 class="no-margin-top panel-title sm-margin-bottom" *ngIf="questionDraft.question.typeId">
        <span>{{ questionDraft.question.typeExtensionName || questionDraft.question.typeName }} question</span>
      </h4>
      <edit-quiz-question
        [question]="questionDraft.question"
        [formOptions]="formOptions"
        [emitter]="emitter"></edit-quiz-question>

      <!--Weight-->
      <div
        *ngIf="quiz.scoreTypeId && questionDraft.question.typeId"
        class="form-group standard-margin-top"
        [ngClass]="{ 'has-error': questionWeight.invalid && questionWeight.dirty }">
        <label class="col-sm-3 control-label" for="questionWeight">Question weight</label>
        <div class="col-sm-4">
          <input
            id="questionWeight"
            name="questionWeight"
            type="number"
            required
            class="form-control required"
            [(ngModel)]="questionDraft.weight"
            #questionWeight="ngModel"
            min="0"
            max="9999999"
            lmsPattern="^[0-9]*$" />
          <p class="help-block" *ngIf="questionWeight.invalid && questionWeight.dirty">
            <span *ngIf="questionWeight.errors?.required">Question weight is required.</span>
            <span *ngIf="questionWeight.errors?.pattern">Question weight is incorrect.</span>
            <span *ngIf="questionWeight.errors?.max">Question weight is incorrect.</span>
          </p>
        </div>
      </div>
    </div>
  </form>

  <!--Invisible control for the validation purposes Start-->
  <div
    class="required"
    [ngClass]="{ 'has-error': (questionsInput.invalid || questionsInput.dirty) && formOptions.submitted }">
    <input
      type="hidden"
      name="questionsInput"
      #questionsInput="ngModel"
      [ngModel]="activeQuestions.length ? 1 : null"
      required />

    <div class="error-block" *ngIf="(questionsInput.invalid || questionsInput.dirty) && formOptions.submitted">
      <span *ngIf="questionsInput.errors?.required">At least one active question is required.</span>
    </div>
  </div>
  <!--Invisible control for the validation purposes End-->

  <!--Draggable Questions list-->
  <div cdkDropList *ngIf="!questionBankEnabled">
    <div
      *ngFor="let quizQuestion of quiz.questions"
      [ngModelGroup]="'question' + quizQuestion.globalId"
      #questionForm="ngModelGroup"
      class="panel standard-margin-top quiz-panel"
      id="{{ quizQuestion.globalId }}"
      [ngClass]="{
        'panel-default':
          !formOptions.submitted ||
          quizQuestionsForm.controls['questionAnswersForm' + quizQuestion.question.globalId]?.valid,
        'panel-danger':
          quizQuestionsForm.controls['questionAnswersForm' + quizQuestion.question.globalId]?.invalid &&
          formOptions.submitted,
        'quizz-disabled': !quizQuestion.question.active,
      }"
      [cdkDragLockAxis]="'y'"
      cdkDrag
      [cdkDragData]="quizQuestion"
      (cdkDragDropped)="moved($event)">
      <!--Question info Start-->
      <div class="panel-heading" [ngClass]="{ isEdit: isEditMode() }">
        <div class="question">
          <div class="flex flex-vertical-top">
            <div class="flex-row visible-xs">
              <div
                class="flex-col flex-col-grow"
                *ngIf="quizQuestion.question.image?.id || quizQuestion.question.tmpImageFile">
                <div>
                  {{ quizQuestion.question.tmpImageFile ? quizQuestion.question.tmpImageFile : 'empty' }}
                  <div *ngIf="quizQuestion.question.image?.id" class="thumbnail-control" style="height: auto">
                    <img
                      style="max-height: 100%; max-width: 100%"
                      alt="Question image"
                      class="thumbnail standard-margin-bottom"
                      src="/a/media/file/{{ quizQuestion.question.image.id }}/?rnd={{
                        quizQuestion.question.image.rnd
                      }}" />
                  </div>
                  <thumbnail
                    class="thumbnail-image"
                    *ngIf="quizQuestion.question.tmpImageFile"
                    [file]="tmpFiles[quizQuestion.question.tmpImageFile.tmpName].file"
                    [params]="{ width: 80, height: 80 }"></thumbnail>
                </div>
              </div>
            </div>

            <div class="flex-row gap-5">
              <!--general row-->

              <!--d-n-drop-->
              <div class="flex-col flex-col-1x hide-edit hidden-xs">
                <span class="use-move-cursor" cdkDragHandle [cdkDragHandleDisabled]="isEditMode()">
                  <i class="fa-solid fa-grip-vertical"></i>
                </span>
              </div>

              <!--image big screen-->
              <div
                class="flex-col hidden-xs"
                *ngIf="quizQuestion.question.image?.id || quizQuestion.question.tmpImageFile">
                <div>
                  <div *ngIf="quizQuestion.question.image?.id" class="thumbnail-control" style="height: auto">
                    <img
                      style="max-height: 100%; max-width: 100%"
                      alt="Question image"
                      class="thumbnail standard-margin-bottom"
                      src="/a/media/file/{{ quizQuestion.question.image.id }}/?rnd={{
                        quizQuestion.question.image.rnd
                      }}" />
                  </div>
                  <thumbnail
                    class="thumbnail-image"
                    *ngIf="quizQuestion.question.tmpImageFile"
                    [file]="tmpFiles[quizQuestion.question.tmpImageFile.tmpName].file"
                    [params]="{ width: 80, height: 80 }"></thumbnail>
                </div>
              </div>

              <div class="flex-col flex-col-grow">
                <div>
                  <div>
                    <span [innerHtml]="quizQuestion.question.text | markdownToHtml"></span>
                  </div>
                  <div *ngIf="quizQuestion.question.category" class="xs-margin-top">
                    <span class="text-muted">{{ quizQuestion.question.category.path }}</span>
                  </div>
                  <div
                    class="xs-margin-top"
                    *ngIf="
                      quizQuestionsForm.controls['questionAnswersForm' + quizQuestion.question.globalId]?.invalid &&
                      formOptions.submitted
                    ">
                    <span class="badge required">!</span>
                  </div>
                </div>
              </div>
              <div class="flex-col hidden-xs" style="position: relative; top: -3px">
                <span class="label label-primary label-sm xs-margin-right">{{
                  quizQuestion.question.typeExtensionName || quizQuestion.question.typeName
                }}</span>
                <span *ngIf="quiz.scoreTypeId !== null">
                  <span class="label label-success label-sm">{{ quizQuestion.weight }} pts</span>
                </span>
              </div>

              <div class="flex-col text-right hide-edit">
                <div ngbDropdown>
                  <div>
                    <button
                      type="button"
                      title="Manage question"
                      class="btn-text item-edit dot-menu-large"
                      ngbDropdownToggle
                      [disabled]="isEditMode()">
                      <span class="fa fa-ellipsis-v" aria-hidden="true"></span>
                      <span class="sr-only">Manage question</span>
                    </button>
                  </div>

                  <div ngbDropdownMenu class="dropdown-menu-right">
                    <a
                      ngbDropdownItem
                      *ngIf="quizQuestion.question.quizId === quiz.id"
                      title="Edit a question"
                      href="#"
                      (click)="editQuestion(quizQuestion)"
                      >Edit</a
                    >
                    <a
                      ngbDropdownItem
                      title="Preview question"
                      href="#"
                      (click)="previewQuestion(quizQuestion.question)"
                      >Preview</a
                    >
                    <a
                      ngbDropdownItem
                      href="#"
                      title="Delete question"
                      (clickConfirmation)="deleteQuestionItem(quizQuestion)"
                      *ngIf="!(quiz.hasResults && quizQuestion.id)"
                      clickConfirmationMessage="Delete question?"
                      ><span class="text-danger">Delete</span>
                    </a>
                    <span
                      ngbDropdownItem
                      class="disabled use-ban-cursor"
                      *ngIf="quiz.hasResults && quizQuestion.id"
                      tabindex="-1"
                      aria-disabled="true"
                      (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
                      (click)="$event.stopPropagation(); $event.preventDefault()">
                      Delete
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-row visible-xs">
              <!--labels on phones-->
              <div class="flex-col">
                <span class="label label-primary label-sm">{{
                  quizQuestion.question.typeExtensionName || quizQuestion.question.typeName
                }}</span>
                <span *ngIf="quiz.scoreTypeId !== null">
                  <span class="label label-success label-sm">{{ quizQuestion.weight }} pts</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Question info End-->

      <!--Edit question module START-->
      <div ngForm #editQuestionForm="ngForm" class="panel panel-inline-edit" *ngIf="questionToEdit === quizQuestion">
        <div class="panel-heading">
          <fieldset>
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <h4 class="xs-margin-top panel-title xs-margin-bottom">
                  {{ questionDraft.question.typeExtensionName || questionDraft.question.typeName }} question
                </h4>
              </div>
              <div class="col-sm-6 col-xs-12 sm-margin-top-phone">
                <button
                  [disabled]="!editQuestionForm.valid"
                  (click)="saveQuestion()"
                  type="button"
                  title="Save Question"
                  class="pull-right pull-left-xs btn btn-success btn-sm">
                  <span class="fa fa-check"></span>
                </button>
                <button
                  (click)="cancelEdit()"
                  type="button"
                  title="Cancel Question Edit"
                  class="pull-right pull-left-xs btn btn-danger btn-sm">
                  <span class="fa fa-times"></span>
                </button>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="panel-body">
          <edit-quiz-question
            [question]="questionDraft.question"
            [emitter]="emitter"
            *ngIf="isQuestionEditable(questionDraft.question)"
            [formOptions]="formOptions"></edit-quiz-question>

          <!--Weight-->
          <div
            *ngIf="quiz.scoreTypeId"
            class="form-group standard-margin-top"
            [ngClass]="{ 'has-error': questionWeight.invalid && questionWeight.dirty }">
            <label class="col-sm-3 control-label" for="questionWeightEdit">Question weight</label>
            <div class="col-sm-4">
              <input
                id="questionWeightEdit"
                name="questionWeight"
                type="number"
                required
                class="form-control required"
                [(ngModel)]="questionDraft.weight"
                #questionWeight="ngModel"
                min="0"
                max="9999999"
                lmsPattern="^[0-9]*$" />
              <p class="help-block" *ngIf="questionWeight.invalid && questionWeight.dirty">
                <span *ngIf="questionWeight.errors?.required">Question weight is required.</span>
                <span *ngIf="questionWeight.errors?.pattern">Question weight is incorrect.</span>
                <span *ngIf="questionWeight.errors?.max">Question weight is incorrect.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--Edit question module END-->
      <!--Edit question module END-->
      <manager-quiz-question-answers
        [question]="quizQuestion.question"
        [answersReadonly]="isEditMode()"
        *ngIf="isQuestionEditable(quizQuestion.question)"
        [messageEditMode]="'inside'"
        [formOptions]="formOptions"
        [autoAdd]="true"></manager-quiz-question-answers>
    </div>
  </div>

  <div *ngIf="questionBankEnabled">
    <div>
      <ul class="list-group">
        <!--Header-->
        <li class="list-group-header font-bold">
          <div class="flex">
            <div class="flex-row">
              <div class="flex-col flex-col-4x">&nbsp;</div>
              <div class="flex-col flex-col-grow">Question</div>
              <div class="flex-col-10x flex-col hidden-xs">Type</div>
              <div class="flex-col-3x flex-col" *ngIf="quiz.scoreTypeId">Points</div>
              <div class="flex-col-2x flex-col sm-margin-left">&nbsp;</div>
            </div>
          </div>
        </li>

        <!--Question list-->
        <li class="list-group-item" *ngFor="let item of questionsLimited; let $index = index">
          <div class="flex">
            <div class="flex-row">
              <div class="flex-col flex-col-4x">
                <button
                  type="button"
                  title="Move up"
                  class="btn btn-link no-padding-all sm-margin-right text-default"
                  (click)="reOrder(item, true)"
                  [disabled]="!$index && currentPage === 1"
                  [ngClass]="{ 'use-ban-cursor text-muted': !$index && currentPage === 1 }">
                  <i class="fa fa-chevron-up" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  title="Move down"
                  class="btn btn-link no-padding-all text-default"
                  (click)="reOrder(item, false)"
                  [ngClass]="{
                    'use-ban-cursor text-muted':
                      quiz.questions.length && quiz.questions[quiz.questions.length - 1] === item,
                  }"
                  [disabled]="quiz.questions.length && quiz.questions[quiz.questions.length - 1] === item">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </button>
              </div>

              <div class="flex-col flex-col-grow standard-margin-right">
                <div href="#" class="text-default no-text-decoration inline-block full-width">
                  <div class="xs-margin-bottom visible-xs pull-right" *ngIf="!item.question.active">
                    <span class="label label-danger label-sm" style="line-height: initial">Inactive</span>
                  </div>

                  <div class="text-truncate" [innerHtml]="item.question.text | markdownToHtml"></div>

                  <div *ngIf="item.question.category" class="text-muted xs-margin-top">
                    {{ item.question.category.path }}
                  </div>
                  <div class="xs-margin-top visible-xs">
                    {{ item.question.typeExtensionName || item.question.typeName }}
                  </div>
                </div>
                <div *ngIf="!item.question.active" class="xs-margin-top">
                  <span class="label label-danger label-sm">Inactive</span>
                </div>
              </div>

              <div class="flex-col flex-col-10x hidden-xs">
                <span>{{ item.question.typeExtensionName || item.question.typeName }}</span>
              </div>
              <div class="flex-col flex-col-3x" *ngIf="quiz.scoreTypeId">
                <span>{{ item.weight }}</span>
              </div>

              <div class="flex-col flex-col-2x text-right sm-margin-left">
                <div ngbDropdown *ngIf="isQuestionEditable(item.question) || quiz.scoreTypeId">
                  <a
                    href="#"
                    title="Manage question"
                    class="btn-sm btn-link text-default dropdown-toggle border-0 manage-question-dropdown-toggle"
                    ngbDropdownToggle>
                    <span class="fa fa-ellipsis-v font-14px fa fa-ellipsis-v text-default"></span>
                    <span class="sr-only">Manage question</span>
                  </a>

                  <div ngbDropdownMenu class="dropdown-menu-right">
                    <a ngbDropdownItem title="Edit question" href="#" (click)="editQuestionModal(item)">Edit</a>
                    <a ngbDropdownItem title="Preview question" href="#" (click)="previewQuestion(item.question)"
                      >Preview</a
                    >
                    <a
                      ngbDropdownItem
                      href="#"
                      title="Delete question"
                      (clickConfirmation)="deleteQuestionItem(item)"
                      *ngIf="!(quiz.hasResults && item.id)"
                      clickConfirmationMessage="Delete question?"
                      ><span class="text-danger">Delete</span></a
                    >
                    <span
                      class="disabled use-ban-cursor"
                      *ngIf="quiz.hasResults && item.id"
                      tabindex="-1"
                      aria-disabled="true"
                      (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
                      (click)="$event.stopPropagation(); $event.preventDefault()">
                      Delete
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div class="text-center">
        <ngb-pagination
          *ngIf="quiz.questions.length > itemsPerPage"
          [collectionSize]="quiz.questions.length"
          [maxSize]="10"
          [rotate]="true"
          [ellipses]="false"
          [boundaryLinks]="true"
          (pageChange)="currentPage = $event"
          [page]="currentPage"
          size="sm">
          <ng-template ngbPaginationFirst>&laquo;</ng-template>
          <ng-template ngbPaginationLast>&raquo;</ng-template>
          <ng-template ngbPaginationPrevious>&lsaquo;</ng-template>
          <ng-template ngbPaginationNext>&rsaquo;</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</form>
