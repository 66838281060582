import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import _ from 'lodash';
import { QuestionIntegrityRulesService } from 'modules/admin/quiz/question/services/question-intergity-rules.service';

@Directive({
  standalone: false,
  selector: '[valid-question-restrictions]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidQuestionRestrictionsValidator, multi: true }],
})
export class ValidQuestionRestrictionsValidator implements Validator {
  @Input() exclude: string[] = [];
  constructor(private questionIntegrityRules: QuestionIntegrityRulesService) {}

  validate(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
      return null;
    }

    const violations = _.filter(
      this.questionIntegrityRules.checkRestrictions(control.value),
      (v) => !this.exclude.includes(v),
    );

    if (violations.length > 0) {
      return Object.fromEntries(violations.map((violation) => [violation, true]));
    }

    return null;
  }
}
