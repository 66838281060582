<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Final Text</h4>
</div>

<div class="modal-body">
  <div class="form-group row">
    <div class="col-xs-12">
      <markdown-editor-component
        [content]="this.quiz.finalText"
        name="finalText"
        (contentChange)="onContentChanged($event)"
        [rows]="5"
        [id]="'finalText'"></markdown-editor-component>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="cancel()" [disabled]="saving">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="saveFinalText()" [ladda]="saving" [disabled]="saving">
    Apply
  </button>
</div>
