import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { ElmsUtils } from 'core/utils';
import { IAchievedPoints, IGroupAchievement } from 'modules/admin/group/achievements/models/group-achievements.model';
import { Observable } from 'rxjs';

enum GroupAchievementsEndpoints {
  GET = '/a/groups/:groupId/achievements/:achievementId/',
  QUERY = '/a/groups/:groupId/achievements/',
  PUT = '/a/groups/:groupId/achievements/',
  POST = '/a/groups/:groupId/achievements/',
  DELETE = '/a/groups/:groupId/achievements/:achievementId/',
  UNPUBLISH = '/a/groups/:groupId/achievements/:achievementId/',
  ACHIEVED_POINTS = '/a/groups/:groupId/achievements/:achievementId/achieved-points/',
}

@Injectable()
export class GroupAchievementsService {
  constructor(private http: HttpClient) {}

  public get(groupId: number, achievementId: number, permitted?: string): Observable<IGroupAchievement> {
    let params = new HttpParams();

    if (permitted?.length) {
      params = params.set('permitted_for', permitted);
    }

    return this.http.get<IGroupAchievement>(
      ElmsUtils.formatUrl(GroupAchievementsEndpoints.GET, { groupId, achievementId }),
      { params },
    );
  }

  public query(groupId: number): Observable<IGroupAchievement[]> {
    return this.http.get<IGroupAchievement[]>(ElmsUtils.formatUrl(GroupAchievementsEndpoints.QUERY, { groupId }));
  }

  public post(groupId: number, payload: Partial<IGroupAchievement>): Observable<IGroupAchievement> {
    return this.http.post<IGroupAchievement>(
      ElmsUtils.formatUrl(GroupAchievementsEndpoints.POST, { groupId }),
      payload,
    );
  }

  public update(groupId: number, payload: IGroupAchievement[]): Observable<IGroupAchievement[]> {
    return this.http.put<IGroupAchievement[]>(
      ElmsUtils.formatUrl(GroupAchievementsEndpoints.PUT, { groupId }),
      payload,
    );
  }

  public delete(groupId: number, achievementId: number): Observable<null> {
    return this.http.delete<null>(ElmsUtils.formatUrl(GroupAchievementsEndpoints.DELETE, { groupId, achievementId }));
  }

  public unpublish(
    groupId: number,
    achievementId: number,
    payload: Partial<IGroupAchievement>,
  ): Observable<IGroupAchievement> {
    return this.http.put<IGroupAchievement>(
      ElmsUtils.formatUrl(GroupAchievementsEndpoints.UNPUBLISH, { groupId, achievementId }),
      payload,
    );
  }

  public achievedPoints(groupId: number, achievementId: number, points: number): Observable<IAchievedPoints[]> {
    const params = new HttpParams().set('points', points.toString());

    return this.http.put<IAchievedPoints[]>(
      ElmsUtils.formatUrl(GroupAchievementsEndpoints.ACHIEVED_POINTS, { groupId, achievementId }),
      null,
      { params },
    );
  }

  getTypes(options?) {
    let params = new HttpParams();

    if (options) {
      params = params.appendAll(options);
    }

    return this.http.get('/a/achievements/types/', {
      params,
      context: new HttpContext().set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }
}
