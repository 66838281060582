import { Component, Injector } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import _ from 'lodash';
import { EditAnswerComponent } from 'modules/admin/quiz/question/components/answers/edit-answer-dialog';

@Component({
  standalone: false,
  selector: 'edit-question-fillin',
  templateUrl: './edit-question-fillin.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class EditQuestionFillinComponent extends EditAnswerComponent {
  longestWordError: boolean;
  pattern = {
    condition: /^[^,]+(,[^,]+)*$/,
    message: 'Need to text separated by commas',
  };

  private readonly wordMaxLength = 100;

  constructor(injector: Injector) {
    super(injector);
  }

  override activate() {
    super.activate();

    this.checkTheLongestWorldLength(this.answerDraft.variants);
  }

  checkTheLongestWorldLength(testString) {
    if (_.isString(testString)) {
      const words = testString.split(',');
      const longestWord = words.reduce(function (a, b) {
        return a.trim().length >= b.trim().length ? a : b;
      });

      this.longestWordError = longestWord.trim().length > this.wordMaxLength;
    }
  }
}
