<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Add questions</h4>
</div>

<div class="modal-body">
  <div>
    <!--Search panel-->
    <div class="row standard-margin-bottom">
      <fieldset [disabled]="searchRequestPromise" class="no-margin-bottom">
        <div class="col-sm-12 col-xs-12 standard-margin-bottom-phone">
          <keyword-search-form
            [(value)]="query"
            (submit)="applySearch()"
            [pending]="!!searchRequestPromise"
            [placeholder]="'Search question by keyword'"></keyword-search-form>
        </div>

        <div class="col-sm-12 col-xs-12 standard-margin-top">
          <div class="flex flex-vertical-center">
            <div class="flex-row">
              <div class="flex-col flex-col-grow well well-sm no-margin-bottom">
                <div>
                  <span *ngIf="itemCount >= 0">{{ itemCount | number }} record(s) found.</span>
                  <a
                    href="#"
                    class="display-inline-block"
                    title="Show/hide inactive questions"
                    (click)="showInactive = !showInactive; applySearch()">
                    {{ showInactive ? 'Hide inactive' : 'Show inactive' }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <quiz-category-search-filter
      (changeHandler)="categoryChanged($event)"
      [useHandler]="true"></quiz-category-search-filter>

    <!--Search Result-->
    <div *ngIf="itemCount">
      <ul class="list-group">
        <!--Header-->
        <li class="list-group-header font-bold hidden-xs">
          <div class="flex">
            <div class="flex-row">
              <div class="flex-col flex-col-2x"></div>
              <div class="flex-col flex-col-grow">
                <div>Question</div>
              </div>
              <div class="flex-col-10x flex-col">Type</div>
            </div>
          </div>
        </li>

        <!--Question list-->
        <li class="list-group-item" *ngFor="let item of items" [ngClass]="{ 'use-ban-cursor': item.added }">
          <div
            class="flex"
            [ngClass]="{ 'disabled-row': item.added }"
            (click)="toggleQuestion(item)"
            (keydown.enter)="toggleQuestion(item)"
            tabindex="0">
            <div class="flex-row">
              <div class="flex-col flex-col-2x">
                <input type="checkbox" [disabled]="item.added" [checked]="item.added || item.selected" />
              </div>
              <div class="flex-col flex-col-grow text-truncate">
                <span class="text-default no-text-decoration inline-block full-width">
                  <div class="xs-margin-bottom visible-xs pull-right" *ngIf="!item.active">
                    <span class="label label-danger label-sm" style="line-height: initial">Inactive</span>
                  </div>
                  <div class="text-truncate" [innerHtml]="item.title"></div>
                  <div *ngIf="item.categoryName" class="text-muted">
                    {{ item.categoryName }}
                  </div>
                  <div *ngIf="!item.active">
                    <span class="label label-danger label-sm">Inactive</span>
                  </div>
                  <div class="xs-margin-top visible-xs">
                    {{ item.typeName }}
                  </div>
                </span>
              </div>
              <div class="flex-col flex-col-10x hidden-xs standard-margin-left">
                <span>{{ item.typeName }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div class="text-center">
        <ngb-pagination
          *ngIf="itemCount > itemsPerPage"
          [collectionSize]="itemCount"
          [maxSize]="itemsPerPage"
          [rotate]="true"
          [ellipses]="false"
          (pageChange)="pageChanged($event)"
          [boundaryLinks]="true"
          [disabled]="!!searchRequestPromise"
          [page]="currentPage"
          size="sm">
          <ng-template ngbPaginationFirst>&laquo;</ng-template>
          <ng-template ngbPaginationLast>&raquo;</ng-template>
          <ng-template ngbPaginationPrevious>&lsaquo;</ng-template>
          <ng-template ngbPaginationNext>&rsaquo;</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-warning" (click)="cancel()" title="Cancel changes">Cancel</button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="submit()"
        title="Add questions"
        [disabled]="totalCheckedQuestions < 1">
        Add {{ totalCheckedQuestions }} questions
      </button>
    </div>
  </div>
</div>
