<fieldset name="header" class="no-margin-bottom">
  <legend class="no-margin-top no-margin-bottom d-flex space-between mb-20 flex-direction-column-xs gap-5">
    <div class="sm-margin-right" *ngIf="quizTypeCategory">{{ quizTypeCategory.name }} Management</div>
    <a
      href="#"
      uiSref="edit.quiz"
      [uiParams]="{ quizId: 'new', quiz_type_id: quizTypeCategoryId }"
      class="btn btn-default mb-5 align-items-center-sm"
      *ngIf="quizTypeCategory"
      >Add {{ quizTypeCategory.name }}</a
    >
  </legend>
</fieldset>

<div *ngIf="allIItemCount === 0 && allIItemCount !== null">
  <div class="list-group-item">
    No {{ quizTypeCategory.name | lowercase }}s found, please
    <a uiSref="edit.quiz" [uiParams]="{ quizId: 'new', quiz_type_id: quizTypeCategoryId }">
      Add {{ quizTypeCategory.name }}
    </a>
  </div>
</div>

<div class="row" *ngIf="allIItemCount">
  <fieldset [disabled]="searchRequestPromise" class="no-margin-bottom">
    <legend class="sr-only">Search items</legend>
    <div class="col-sm-12 col-xs-12 standard-margin-bottom">
      <keyword-search-form
        [(value)]="query"
        (submit)="applySearchFilters()"
        [pending]="searchRequestPromise"
        placeholder="Search by Name, {{
          quizTypeCategory?.name
        }} ID, group, creator ID, or creator name"></keyword-search-form>
    </div>

    <div class="col-sm-12 col-xs-12">
      <div class="well well-sm">
        <span *ngIf="itemCount >= 0">{{ itemCount | number }} record(s) found.</span>
        <a href="#" class="display-inline-block" title="Show/hide inactive questions" (click)="changeStatus()">
          {{ showInactive ? 'Hide inactive' : 'Show inactive' }}
        </a>
      </div>
    </div>
  </fieldset>
</div>

<div class="row">
  <div class="col-xs-12">
    <div>
      <ul class="list-group list-group-striped quiz-list" *ngIf="itemCount">
        <li class="list-group-header">
          <div class="flex">
            <div class="flex-row">
              <div class="flex-col flex-col-grow">
                <a
                  href="#"
                  class="header-sort-link"
                  (click)="applySort(defaultSorting.field)"
                  [ngClass]="{ 'disabled-link': searchRequestPromise }"
                  title="Sort by name"
                  *ngIf="sortingEnabled">
                  <strong>Name</strong>
                  <span
                    class="fa"
                    *ngIf="predicate === defaultSorting.field"
                    [ngClass]="{ 'fa-sort-desc': reverse, 'fa-sort-asc': !reverse }"></span>
                </a>
                <strong *ngIf="!sortingEnabled">Name</strong>
              </div>
              <div class="flex-col flex-col-7x hidden-xs">
                <a
                  href="#"
                  class="header-sort-link"
                  (click)="applySort('questions_count')"
                  [ngClass]="{ 'disabled-link': searchRequestPromise }"
                  title="Sort by questions count"
                  *ngIf="sortingEnabled">
                  <strong>Questions</strong>
                  <span
                    class="fa"
                    *ngIf="predicate === 'questions_count'"
                    [ngClass]="{ 'fa-sort-desc': reverse, 'fa-sort-asc': !reverse }"></span>
                </a>
                <strong *ngIf="!sortingEnabled">Questions</strong>
              </div>
              <div class="flex-col flex-col-7x hidden-xs">
                <a
                  href="#"
                  class="header-sort-link"
                  (click)="applySort('courses_count')"
                  [ngClass]="{ 'disabled-link': searchRequestPromise }"
                  title="Sort by courses count"
                  *ngIf="sortingEnabled">
                  <strong>Courses</strong>
                  <span
                    class="fa"
                    *ngIf="predicate === 'courses_count'"
                    [ngClass]="{ 'fa-sort-desc': reverse, 'fa-sort-asc': !reverse }"></span>
                </a>
                <strong *ngIf="!sortingEnabled">Courses</strong>
              </div>
              <div class="flex-col flex-col-20x hidden-xs">
                <a
                  href="#"
                  class="header-sort-link"
                  (click)="applySort('group_path')"
                  [ngClass]="{ 'disabled-link': searchRequestPromise }"
                  title="Sort by availability"
                  *ngIf="sortingEnabled">
                  <strong>Availability</strong>
                  <span
                    class="fa"
                    *ngIf="predicate === 'group_path'"
                    [ngClass]="{ 'fa-sort-desc': reverse, 'fa-sort-asc': !reverse }"></span>
                </a>
                <strong *ngIf="!sortingEnabled">Availability</strong>
              </div>
              <div class="flex-col flex-col-2x"></div>
            </div>
          </div>
        </li>
        <li class="list-group-item" *ngFor="let item of items">
          <div class="flex">
            <div class="flex-row">
              <div class="flex-col flex-col-grow align-self-stretch xs-margin-right">
                <a
                  href="#"
                  uiSref="main.quiz"
                  [uiParams]="{ id: item.id }"
                  class="text-default no-text-decoration full-width full-height display-inline-block">
                  <span class="label label-danger label-danger-inline" *ngIf="!item.active">Inactive</span>

                  <span [innerHtml]="item.title"></span>

                  <div *ngIf="item.startDate || item.endDate" class="xs-margin-top text-muted">
                    <span *ngIf="item.startDate">from {{ item.startDate | date: 'mediumDate' }}&nbsp;</span>
                    <span *ngIf="item.endDate">to {{ item.endDate | date: 'mediumDate' }}</span>
                  </div>
                </a>
              </div>
              <div class="flex-col flex-col-7x hidden-xs align-self-stretch">
                <a
                  href="#"
                  uiSref="main.quiz"
                  [uiParams]="{ id: item.id }"
                  class="text-default no-text-decoration full-width full-height display-inline-block">
                  {{ item.questionsCount }}
                </a>
              </div>
              <div class="flex-col flex-col-7x hidden-xs align-self-stretch">
                <a
                  href="#"
                  uiSref="main.quiz"
                  [uiParams]="{ id: item.id }"
                  class="text-default no-text-decoration full-width full-height display-inline-block">
                  {{ item.coursesCount }}
                </a>
              </div>
              <div class="flex-col flex-col-20x hidden-xs align-self-stretch">
                <a
                  href="#"
                  uiSref="main.quiz"
                  [uiParams]="{ id: item.id }"
                  class="text-default no-text-decoration full-width full-height display-inline-block">
                  <span *ngFor="let group of item.groupName; let last">{{ group }}{{ last ? '' : ', ' }}</span>
                </a>
              </div>

              <div class="flex-col flex-col-2x text-right align-self-stretch">
                <quiz-edit-actions-component
                  [quiz]="item"
                  [quizTypeName]="quizTypeCategory?.name"
                  (successHandler)="actionsHandler()"></quiz-edit-actions-component>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="text-center">
        <ngb-pagination
          *ngIf="itemCount > 10"
          [collectionSize]="itemCount"
          [maxSize]="10"
          [rotate]="true"
          [ellipses]="false"
          (pageChange)="pageChanged($event)"
          [boundaryLinks]="true"
          [pageSize]="itemsPerPage"
          [disabled]="searchRequestPromise"
          [page]="currentPage"
          size="sm"
          class="pagination-sm">
          <ng-template ngbPaginationFirst>&laquo;</ng-template>
          <ng-template ngbPaginationLast>&raquo;</ng-template>
          <ng-template ngbPaginationPrevious>&lsaquo;</ng-template>
          <ng-template ngbPaginationNext>&rsaquo;</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
