import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { IQuizQuestionEvent } from 'modules/admin/quiz/question';
import { IQuiz, IQuizQuestion } from 'modules/quiz/models/quiz.model';
import { Subject } from 'rxjs';

@Component({
  standalone: false,
  selector: 'in-quiz-question-edit-modal',
  templateUrl: './in-quiz-question-edit-modal.component.html',
})
export class InQuizQuestionEditModalComponent implements OnInit {
  quiz: IQuiz;
  emitter = new Subject<IQuizQuestionEvent>();
  quizQuestion: IQuizQuestion;

  questionDraft: IQuizQuestion;

  constructor(
    private activeModal: NgbActiveModal,
    private notificationService: NotificationService,
  ) {}

  get isQuestionEditable() {
    return !this.questionDraft.question.id || this.questionDraft.question.quizId === this.quiz.id;
  }

  ngOnInit() {
    this.questionDraft = Object.assign({}, this.quizQuestion);
  }

  cancel() {
    this.activeModal.dismiss('cancel');
  }

  submit(form: FormGroup) {
    if (form.invalid) {
      this.notificationService.error('Please fill in all required fields', 2e3);

      return;
    }

    this.emitter.next({ event: 'event:quizQuestion:beforeSave', question: this.questionDraft.question });
    this.activeModal.close(this.questionDraft);
  }
}
