import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import _ from 'lodash';
import { DeleteQuizQuestionComponent } from 'modules/admin/quiz/question/components/modal/delete-quiz-question.component';
import { InQuizQuestionEditModalComponent } from 'modules/admin/quiz/question/components/modal/in-quiz-question-edit-modal.component';
import { MoveQuestionConfirmationDialogComponent } from 'modules/admin/quiz/question/components/modal/move-question-confirmation-dialog.component';
import { PreviewQuizQuestionComponent } from 'modules/admin/quiz/question/components/modal/question-preview.component';
import { QuizQuestionsSelectionModalComponent } from 'modules/admin/quiz/question/components/modal/questions-selection-modal.component';
import { IQuestion, IQuiz, IQuizQuestion, ISkipQuestionRule } from 'modules/quiz/models/quiz.model';
import { QuizQuestionService } from 'modules/quiz/services/quiz-question.service';
import { Observable, from, map, timeout } from 'rxjs';

@Injectable()
export class QuizQuestionsManagerService {
  constructor(
    private http: HttpClient,
    private ngbModal: NgbModal,
    private quizQuestionService: QuizQuestionService,
  ) {}

  removeQuizQuestion(question: IQuestion): Promise<void> {
    const modalRef = this.ngbModal.open(DeleteQuizQuestionComponent, {
      keyboard: false,
    });

    modalRef.componentInstance.question = question;

    return modalRef.result;
  }

  prepareQuestionCopy(
    question: IQuestion,
    parentId?: number,
    quizId?: number,
    globalId?: string,
    newVersion?: number,
  ): Partial<IQuestion> {
    delete question.id;
    question.answerVariants = question.answerVariants.map(function (item) {
      delete item.id;

      return item;
    });

    if (quizId) {
      question.quizId = quizId;
    }

    if (globalId) {
      question.globalId = globalId;
    }

    question.parentId = parentId;

    if (!newVersion) {
      question.text = question.text + ' Copy';
    }

    return this.quizQuestionService.newQuestion(question);
  }

  previewQuestion(question: IQuestion, scoreTypeId?: number): Observable<any> {
    const modalRef = this.ngbModal.open(PreviewQuizQuestionComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'preview-quiz',
    });

    modalRef.componentInstance.question = question;
    modalRef.componentInstance.scoreTypeId = scoreTypeId;

    return from(modalRef.result);
  }

  showInQuizQuestionEditDialog(quizQuestion: IQuizQuestion, quiz: IQuiz): Promise<any> {
    const modalRef = this.ngbModal.open(InQuizQuestionEditModalComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'preview-quiz',
    });

    modalRef.componentInstance.quizQuestion = quizQuestion;
    modalRef.componentInstance.quiz = quiz;

    return modalRef.result;
  }

  showQuestionSelectionDialog(quizTypeId: number, selectedQuestions: IQuizQuestion[]): Promise<IQuestion[]> {
    const modalRef = this.ngbModal.open(QuizQuestionsSelectionModalComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'preview-quiz',
    });

    modalRef.componentInstance.quizTypeId = quizTypeId;
    modalRef.componentInstance.selectedQuestions = selectedQuestions;

    return modalRef.result;
  }

  getSkipQuestionAnswers(quizId: number): Observable<ISkipQuestionRule[]> {
    return this.http.get<ISkipQuestionRule[]>(`/a/quizzes/${quizId}/skip-question-answers`);
  }

  updateQuizQuestionsList(
    quizId: number,
    quizQuestions: IQuizQuestion[],
    ttl?: number,
    throwHttpErrors = false,
  ): Observable<IQuizQuestion[]> {
    const questionsList = _.map(quizQuestions, (quizQuestion) => {
      return _.omit(quizQuestion, ['question']);
    });

    const result = this.http
      .put<
        IQuizQuestion[]
      >(`/a/quizzes/${quizId}/`, { questions: questionsList }, { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) })
      .pipe(map((response) => _.get(response, 'questions')));

    if (ttl) {
      return result.pipe(timeout(ttl));
    }

    return result;
  }

  showMoveQuestionConfirmationDialog() {
    this.ngbModal.open(MoveQuestionConfirmationDialogComponent, {
      backdrop: 'static',
    });
  }

  saveSkipQuestionAnswer(quizId: number, answerId: number, skipToQuestionId: number): Observable<ISkipQuestionRule> {
    return this.http.put<ISkipQuestionRule>(`/a/quizzes/${quizId}/skip-question-answers/${answerId}/`, {
      question_id: skipToQuestionId,
    });
  }

  deleteSkipQuestionAnswer(quizId: number, answerId: number): Observable<null> {
    return this.http.delete<null>(`/a/quizzes/${quizId}/skip-question-answers/${answerId}/`);
  }
}
