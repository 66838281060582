import { Injectable } from '@angular/core';
import { ISearchItem, SearchService } from 'modules/search/services';

export interface IQuizQuestionSearchItem extends ISearchItem {
  categoryName: string;
  selected: boolean;
  added: boolean;
}

@Injectable()
export class QuizQuestionsSearchService extends SearchService<IQuizQuestionSearchItem> {
  static override readonly endpoint = '/a/admin/search/';
  override type = 'quiz_question';
  override sequentRequestItemsCount = 10;
  protected override get endpoint(): string {
    return QuizQuestionsSearchService.endpoint;
  }
}
