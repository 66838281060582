import { Component, Injector } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { EditAnswerComponent } from 'modules/admin/quiz/question/components/answers/edit-answer-dialog';

@Component({
  standalone: false,
  selector: 'edit-question-matching',
  templateUrl: './edit-question-matching.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class EditQuestionMatchingComponent extends EditAnswerComponent {
  constructor(injector: Injector) {
    super(injector);
  }
}
