<div *ngIf="loading">
  <div class="load-spinner"></div>
</div>

<div *ngIf="!loading && helpText">
  <h2 class="standard-margin-bottom">{{ helpText.name }}</h2>
  <hr />
  <p [innerHtml]="helpText.text | markdownToHtml"></p>
</div>

<div *ngIf="!loading && !helpText">
  <p>Help & Support information content is empty.</p>
</div>
