import { Injectable } from '@angular/core';
import _ from 'lodash';
import { QuizQuestionType, questionTypeExtension } from 'modules/quiz';
import { IAnswerVariant, IQuestion } from 'modules/quiz/models/quiz.model';

export interface IQuestionRulesRestrictions {
  correctLimit: null | number;
  maxVariants: null | number;
  minVariants: null | number;
  correctLack: boolean;
  noTextDuplicates: null | boolean;
  noTextAnswerDuplicates: null | boolean;
}

@Injectable()
export class QuestionIntegrityRulesService {
  restrictions: { [key: number]: IQuestionRulesRestrictions } = {};

  constructor() {
    this.fillInRestrictions();
  }

  checkRestrictions(question: IQuestion) {
    const questionRestrictions = this.restrictions[question.typeId * 100 + (question.typeExtensionId || 0)];

    const violations = [];

    if (questionRestrictions) {
      if (question.answerVariants) {
        if (
          questionRestrictions.maxVariants !== null &&
          question.answerVariants.length > questionRestrictions.maxVariants
        ) {
          violations.push('maxVariants');
        }

        if (
          questionRestrictions.correctLimit !== null &&
          _.filter(question.answerVariants, 'correct').length > questionRestrictions.correctLimit
        ) {
          violations.push('correctLimit');
        }

        if (
          questionRestrictions.correctLack &&
          question.answerVariants.length > 0 &&
          _.filter(question.answerVariants, 'correct').length === 0
        ) {
          violations.push('correctLack');
        }
      }

      if (
        questionRestrictions.minVariants &&
        (!question.answerVariants || question.answerVariants.length < questionRestrictions.minVariants)
      ) {
        violations.push('minVariants');
      }

      if (
        questionRestrictions.noTextDuplicates &&
        question.answerVariants.length > 1 &&
        _.uniqBy(question.answerVariants, 'text').length !== question.answerVariants.length
      ) {
        violations.push('hasTextDuplicates');
      }

      if (
        questionRestrictions.noTextAnswerDuplicates &&
        question.answerVariants.length > 1 &&
        _.uniqBy(question.answerVariants, 'answerText').length !== question.answerVariants.length
      ) {
        violations.push('hasTextAnswerDuplicates');
      }
    }

    return violations;
  }

  fillInRestrictions() {
    this.restrictions[QuizQuestionType.multiChoice * 100] = {
      correctLimit: 1,
      maxVariants: null, //No restriction
      minVariants: 1,
      correctLack: true,
      noTextDuplicates: true, //No duplicates
      noTextAnswerDuplicates: null, //No restriction
    };

    this.restrictions[questionTypeExtension.likertScale.typeId * 100 + questionTypeExtension.likertScale.id] = {
      correctLimit: null,
      maxVariants: null, //No restriction
      minVariants: 1,
      correctLack: true,
      noTextDuplicates: true, //No duplicates
      noTextAnswerDuplicates: null, //No restriction
    };

    this.restrictions[questionTypeExtension.trueFalse.typeId * 100 + questionTypeExtension.trueFalse.id] = {
      correctLimit: 1,
      maxVariants: null, //No restriction
      minVariants: 1,
      correctLack: true,
      noTextDuplicates: true, //No duplicates
      noTextAnswerDuplicates: null, //No restriction
    };

    this.restrictions[questionTypeExtension.yesNo.typeId * 100 + questionTypeExtension.yesNo.id] = {
      correctLimit: 1,
      maxVariants: null, //No restriction
      minVariants: 1,
      correctLack: true,
      noTextDuplicates: true, //No duplicates
      noTextAnswerDuplicates: null, //No restriction
    };

    this.restrictions[QuizQuestionType.checkAllThatApply * 100] = {
      correctLimit: null, //No restriction
      maxVariants: null, //No restriction
      minVariants: 1,
      correctLack: true,
      noTextDuplicates: true, //No duplicates
      noTextAnswerDuplicates: null, //No restriction
    };

    this.restrictions[QuizQuestionType.shortAnswer * 100] = {
      correctLimit: null, //No restriction
      maxVariants: 0, //No choices
      minVariants: 0, //No choices
      correctLack: true,
      noTextDuplicates: null, //No restriction
      noTextAnswerDuplicates: null, //No restriction
    };

    this.restrictions[QuizQuestionType.matching * 100] = {
      correctLimit: null, //No restriction
      maxVariants: null, //No restriction
      minVariants: 2,
      correctLack: false,
      noTextDuplicates: true, //No duplicates
      noTextAnswerDuplicates: true, //No restriction
    };

    this.restrictions[QuizQuestionType.fillin * 100] = {
      correctLimit: null, //No restriction
      maxVariants: null, //No restriction
      minVariants: 1,
      correctLack: false,
      noTextDuplicates: null, //No restriction
      noTextAnswerDuplicates: null, //No restriction
    };

    return this.restrictions;
  }

  checkAnswerVariantCorrectnessRelevance(question: IQuestion, answer: IAnswerVariant) {
    if (!question.answerVariants) {
      return true;
    }

    const index = question.typeId * 100 + (question.typeExtensionId || 0);
    const limit = this.restrictions[index]?.correctLimit ?? 0;

    if (limit > 1) {
      return (
        _.filter(question.answerVariants, function (item) {
          return item !== answer && item.correct;
        }).length < limit
      );
    }

    return true;
  }

  adjustAnswerVariantsCorrectness(question: IQuestion, answer: IAnswerVariant) {
    if (!question.answerVariants || !question.answerVariants.length || !answer.correct) {
      return;
    }

    const index = question.typeId * 100 + (question.typeExtensionId || 0);
    const limit = this.restrictions[index]?.correctLimit ?? 0;

    if (limit === 1) {
      for (let i = question.answerVariants.length - 1; i >= 0; i--) {
        if (question.answerVariants[i] !== answer) {
          question.answerVariants[i].correct = false;
        }
      }
    }
  }
}
