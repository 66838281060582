import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { IQuiz } from 'modules/quiz/models/quiz.model';
import { QuizService } from 'modules/quiz/services/quiz.service';
import { EMPTY, Unsubscribable, catchError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'delete-quiz-modal',
  templateUrl: './delete-quiz.component.html',
})
export class DeleteQuizComponent implements OnInit, OnDestroy {
  quiz?: IQuiz;
  quizTypeName: string;
  reload = false;
  previewError: string;

  deleteError = '';
  deletionProgress = false;
  reloading = false;
  private subscriber?: Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    private quizService: QuizService,
    private backUrlService: BackUrlService,
  ) {}

  ngOnInit() {
    if (this.reload) {
      this.reloading = true;
      this.subscriber = this.quizService
        .get(this.quiz.id)
        .pipe(
          finalize(() => {
            this.subscriber?.unsubscribe();
            delete this.subscriber;
            this.reloading = false;
          }),
          catchError(() => {
            this.previewError = 'Failed to load quiz';

            return null;
          }),
        )
        .subscribe((result) => {
          if (result) {
            this.quiz = result as IQuiz;
          }
        });
    }
  }

  deleteQuiz() {
    this.deletionProgress = true;
    this.subscriber = this.quizService
      .delete(this.quiz.id, true)
      .pipe(
        tap(() => {
          this.backUrlService.forgetState('main.quiz', { id: this.quiz.id.toString(), page: null });
          this.activeModal.close(true);
        }),
        catchError((err: HttpErrorResponse) => {
          this.deleteError = err.error;

          return EMPTY;
        }),
        finalize(() => {
          this.subscriber.unsubscribe();
          delete this.subscriber;
          this.deletionProgress = false;
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
