import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { IQuizSearchItem } from 'modules/admin/quiz/models/quiz.model';
import { QuizManagerService } from 'modules/admin/quiz/services/quiz-manager.service';
import { QuizService } from 'modules/quiz/services/quiz.service';
import { switchMap } from 'rxjs';

@Component({
  standalone: false,
  selector: 'quiz-edit-actions-component',
  templateUrl: './quiz-edit-actions.component.html',
})
export class QuizEditActionsComponent implements OnInit {
  @Input() quiz: IQuizSearchItem;
  @Input() quizTypeName: string;
  @Output() successHandler = new EventEmitter<IQuizSearchItem>();
  @Input() showInactive: boolean;
  canEdit = false;

  private readonly roles = this.activeState.current.data ? this.activeState.current.data.adminRoleLimits : null;
  private readonly previewRoles = [50];
  private readonly canEditRoles = this.roles ? this.roles.filter((role) => !this.previewRoles.includes(role)) : [];

  constructor(
    private currentUser: CurrentUserService,
    private activeState: UIRouterGlobals,
    private quizManagerService: QuizManagerService,
    private quizService: QuizService,
  ) {}

  ngOnInit() {
    const user = this.currentUser.get();

    this.canEdit =
      user.isInRole(this.canEditRoles) || (user.isInRole(this.previewRoles) && user.id === this.quiz.createdBy);
  }

  cloneQuiz(quiz: IQuizSearchItem): void {
    this.quizManagerService.cloneQuiz({ id: parseInt(quiz.id, 10) }, this.quizTypeName, true);
  }

  previewQuiz(quiz: IQuizSearchItem) {
    this.quizManagerService.previewQuiz({ id: parseInt(quiz.id, 10) }, this.quizTypeName, true);
  }

  setActiveFlag(item: IQuizSearchItem, active: boolean): void {
    this.quizService
      .get(item.id)
      .pipe(
        switchMap((quiz) => {
          return this.quizManagerService.setActiveFlag(quiz, active);
        }),
      )
      .subscribe((success) => {
        if (success) {
          this.quiz.active = active;
        }
      });
  }

  remove(quiz: IQuizSearchItem): void {
    this.quizManagerService
      .showDeleteQuizDialog({ id: parseInt(quiz.id, 10) }, this.quizTypeName, true)
      .subscribe((result) => {
        if (result) {
          this.successHandler.emit(quiz);
        }
      });
  }
}
