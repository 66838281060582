<div class="panel panel-inline-edit">
  <div class="panel-heading clearfix">
    <fieldset [disabled]="fileUploadProgress" class="no-margin-bottom">
      <span>Edit Answer Field</span>
      <button
        [disabled]="!editQuestionFillinForm.valid || longestWordError"
        (click)="submit(editQuestionFillinForm)"
        type="button"
        title="Save an answer"
        class="pull-right btn btn-success btn-sm">
        <span class="fa fa-check"></span>
      </button>
      <button
        (click)="cancel()"
        type="button"
        title="Cancel Answer Field edit"
        class="pull-right btn btn-danger btn-sm">
        <span class="fa fa-times"></span>
      </button>
    </fieldset>
  </div>
  <form class="panel-body" name="editQuestionFillinForm" #editQuestionFillinForm="ngForm">
    <!--FillIn Variants-->
    <div class="form-group">
      <div
        class="col-xs-12"
        [ngClass]="{ 'has-error': (longestWordError || fillinVariants.invalid) && fillinVariants.dirty }">
        <label [for]="'fillinVariants'">
          Separate all accepted answers for this field by commas (ex. Answer1, Answer2, Answer3)
        </label>
        <markdown-editor-component
          [(ngModel)]="answerDraft.variants"
          name="fillinVariants"
          [lmsPattern]="pattern.condition"
          (ngModelChange)="checkTheLongestWorldLength($event)"
          [required]="true"
          [rows]="5"
          #fillinVariants="ngModel"
          [hideButtons]="['urlLinkButton', 'imageButton']"
          [disableAttachments]="true"
          [maxlength]="2000"
          noWhiteSpace
          [id]="'fillinVariants'"
          autoFocus></markdown-editor-component>
        <input type="hidden" name="LongestWordError" [ngModel]="longestWordError ? null : 1" required />

        <p class="help-block" *ngIf="(longestWordError || fillinVariants.invalid) && fillinVariants.dirty">
          <span *ngIf="fillinVariants.errors?.required">Answer text is required.</span>
          <span *ngIf="fillinVariants.errors?.pattern">{{ pattern.message }}</span>
          <span *ngIf="longestWordError">The max length for answer variant is 100 symbols.</span>
        </p>
      </div>
    </div>
  </form>
</div>
