import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IInstructor } from 'modules/instructors/models/instructor.model';
import { InstructorsService } from 'modules/instructors/services/instructor.service';
import { EMPTY, Unsubscribable, catchError, finalize, tap } from 'rxjs';

@Component({
  standalone: false,
  selector: 'delete-instructor',
  templateUrl: './delete-instructor.component.html',
})
export class DeleteInstructorComponent implements OnDestroy {
  deletionProgress = false;
  instructor: IInstructor;
  deleteError = '';
  private requestSubscriber?: Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    private instructorsService: InstructorsService,
  ) {}

  ngOnDestroy(): void {
    this.clearRequestSubscriber();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    this.deletionProgress = true;

    this.requestSubscriber = this.instructorsService
      .delete(this.instructor.id, true)
      .pipe(
        tap(() => this.activeModal.close(this.instructor.id)),
        catchError((e: HttpErrorResponse) => this.handleError(e)),
        finalize(() => this.clearRequestSubscriber()),
      )
      .subscribe();
  }

  private handleError(e: HttpErrorResponse) {
    this.deleteError = e.error;

    return EMPTY;
  }

  private clearRequestSubscriber(): void {
    this.deletionProgress = false;

    if (this.requestSubscriber) {
      this.requestSubscriber.unsubscribe();
      delete this.requestSubscriber;
    }
  }
}
