<div class="modal-header" *ngIf="question">
  <h4 class="modal-title">
    <span>{{ question.typeExtensionName || question.typeName }} question</span>
  </h4>
</div>

<div class="modal-body">
  <quiz-player-question [question]="question" *ngIf="!reloading" [scoreTypeId]="scoreTypeId"></quiz-player-question>
  <div *ngIf="reloading && !previewError">
    <div class="load-spinner"></div>
  </div>
  <div *ngIf="previewError" class="alert alert-sm alert-danger">
    {{ previewError }}
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning pull-right" (click)="closeModal()" autoFocus>Close</button>
</div>
