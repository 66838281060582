import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsAuthService } from 'ajs/modules/app/authorization/elms-auth-service';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { AlertType, IAlert } from 'modules/alerts/models/alert.model';
import { StaticAlertsService } from 'modules/alerts/services/static-alerts.service';
import { Observable } from 'rxjs';
import { ImpersonationUser } from '../models/user.model';

export enum ImpersonationEndpoints {
  CHECK_USER = '/a/user/impersonation/',
  IMPERSONATE = '/a/user/impersonation/',
}

export interface ImpersonationResponse {
  success: boolean;
  message: string;
  status?: number;
  data: ImpersonationUser | null;
}

@Injectable()
export class ImpersonationService {
  constructor(
    private http: HttpClient,
    private staticAlertsService: StaticAlertsService,
    private elmsAuthService: ElmsAuthService,
  ) {
    this.showAlertIfNecessary();
  }

  checkUser(loginNameOrId: string | number): Observable<ImpersonationUser> {
    return this.http.get<ImpersonationUser>(ImpersonationEndpoints.CHECK_USER, { params: { loginNameOrId } });
  }

  impersonate(
    userId: number,
    username: string,
    password: string,
    throwHttpErrors = false,
  ): Observable<ImpersonationResponse> {
    return this.http.post<ImpersonationResponse>(
      ImpersonationEndpoints.IMPERSONATE,
      { userId, password, username },
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) },
    );
  }

  private showAlertIfNecessary() {
    if (!this.elmsAuthService.isImpersonated()) {
      return;
    }

    const impersonateAlert: IAlert = {
      active: true,
      visibility: true,
      visible_only_to_logged_in_users: true,
      text: '',
      alwaysOnTop: true,
      alertType: AlertType.IMPERSONATE,
      type: {
        id: 0,
        name: 'Failure',
      },
    };

    this.staticAlertsService.push(impersonateAlert);
  }
}
