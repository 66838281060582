<div class="row form-group no-margin-bottom" *ngIf="categories.length">
  <div class="col-sm-2 col-xs-12 xs-margin-top no-margin-top-phone">
    <label for="categorySelection">Filter by Category</label>
  </div>

  <div class="col-sm-5 col-xs-12 standard-margin-bottom">
    <select
      class="form-control"
      [(ngModel)]="parentId"
      id="categorySelection"
      (ngModelChange)="parentChanged($event)"
      name="categorySelection">
      <option value="">Select Category</option>
      <option [value]="categoryItem.id" *ngFor="let categoryItem of categories">{{ categoryItem.name }}</option>
    </select>
  </div>

  <div class="col-sm-5 col-xs-12 standard-margin-bottom">
    <select
      class="form-control"
      [(ngModel)]="categoryId"
      id="subCategorySelection"
      (ngModelChange)="categoryChanged()"
      name="subCategorySelection">
      <option value="">Select Sub-Category</option>
      <option [value]="categoryItem.id" *ngFor="let categoryItem of subCategories">{{ categoryItem.name }}</option>
    </select>
  </div>
</div>
